'use strict';

angular.module('cpformplastApp.inventory')
    .controller('finishedProductController', function($scope, DataManager, $state, Notification, Util) {

    $scope.finishedProducts = [];

    $scope.tableButtons = [{
      icon: 'print',
      title: 'Imprimer' ,
      'function': () => {
        $scope.printContent();
      }
    }];

    $scope.tableStructure = [{
      'title':'Job',
      'value':'job',
      'key':'job',
      'width': 100,
      'filter':'alpha'
    },{
      'title':'Client',
      'value':'client',
      'key':'client',
      'width': 300,
      'filter':'alpha'
    },{
      'title':'Moule',
      'value':'item',
      'key':'item',
      'width': 100,
      'filter':'alpha'
    },{
      'title':'#PO',
      'value':'po',
      'key':'po',
      'width': 150,
      'filter':'alpha'
    },{
      'title':'#Code',
      'value':'code',
      'key':'code',
      'width': 120,
      'filter':'alpha'
    },{
      'title':'Unités',
      'value':'quantity',
      'key':'quantity',
      'width': 100,
      'filter':'num'
    },{
      'title':'Quantité (palettes)',
      'value':'quantityInSkid',
      'key':'quantityInSkid',
      'width': 150,
      'filter':'num'
    }];

    $scope.init = function() {
      DataManager.fetchGroupedFinishedProducts().then((data) => {
        for (var i=0 ; i< data.length ; i++) {
          $scope.finishedProducts.push({
            '_id': data[i]._id,
            'job': data[i].job.number,
            'client': data[i].job.item.client.name,
            'item': data[i].job.item.name,
            'po': data[i].job.po,
            'code': data[i].job.item.code,
            'quantity': data[i].quantity,
            'quantityInSkid': data[i].skid,
          });
        }
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu lors du chargement des livraisons');
      });
    };

    $scope.selectFinishedProduct = function(finishedProduct) {
      $state.go('inventory/grid', {'search': finishedProduct.job, 'filter': 'finished-product', 'finishedProductId': finishedProduct._id});
    };

    $scope.printContent = function() {
      let title = "Produits finis (" + Util.getDateTimeString(new Date()) + ")";
      let columns = $scope.tableStructure;
      var sections = [{
        title: '',
        elements: $scope.finishedProducts
      }];

      let tableStructure = {
        title: title,
        columns: columns,
        sections: sections
      };

      DataManager.downloadPdfTable(tableStructure, "produits-finis");
    };

    $scope.init();
});
